<template>
  <div
    v-if="
      curatorSettings &&
      mainStore.currentSite &&
      mainStore.currentSite.settings.curator
    "
    class="bg-[#000000] md:px-16"
  >
    <div class="mx-auto max-w-[2560px] py-6 px-4">
      <div :id="curatorSettings.id" />
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

import { useMainStore } from "@/store/index";

const curatorFeeds = {
  // International
  default: {
    id: "curator-feed-default-feed-layout",
    src: "https://cdn.curator.io/published/099534ee-18dd-4120-99de-3aa7b852b048.js",
  },
  // Italy
  it: {
    id: "curator-feed-new-feed-layout",
    src: "https://cdn.curator.io/published/a7942a20-794b-4f55-bf54-c1d36e9f1315.js",
  },
  // Malasiya
  my: {
    id: "curator-feed-mbp-malaysia-layout",
    src: "https://cdn.curator.io/published/baec96f0-9493-4255-8071-b0d158fdab45.js",
  },
  // Spain
  es: {
    id: "curator-feed-mbp-spain-layout",
    src: "https://cdn.curator.io/published/91f87784-30bf-4e5e-821e-28c323ba0583.js",
  },
  // Bangladesh
  bd: {
    id: "curator-feed-mbp-bangladesh-layout",
    src: "https://cdn.curator.io/published/8abaedf6-50fc-46e3-8229-8095404376c3.js",
  },
  // France
  fr: {
    id: "curator-feed-mbp-france-layout",
    src: "https://cdn.curator.io/published/c5b9639e-5d50-484b-ae31-000647ceca97.js",
  },
  // Greece
  gr: {
    id: "curator-feed-morbidelli-greece-layout",
    src: "https://cdn.curator.io/published/1a4d7fc6-750e-45dd-b22b-803714d35733.js",
  },
  // Poland
  pl: {
    id: "curator-feed-mbp-poland-layout",
    src: "https://cdn.curator.io/published/77d744ba-15de-4897-8fef-a5026e6b17df.js",
  },
  // Portugal
  pt: {
    id: "curator-feed-morbidelli-portugal-layout",
    src: "https://cdn.curator.io/published/991623d8-924d-49d5-98da-0060951c6230.js",
  },
  // Slovenia
  si: {
    id: "curator-feed-mbp-slovenia-layout",
    src: "https://cdn.curator.io/published/79a7070d-cc9f-468a-9171-7f169e3a7247.js",
  },
  // United Kingdom
  gb: {
    id: "curator-feed-mbp-uk-layout",
    src: "https://cdn.curator.io/published/63f4c89a-81ff-40f9-a711-480f4a8eb68f.js",
  },
  // Argentina
  ar: {
    id: "curator-feed-new-feed-layout1",
    src: "https://cdn.curator.io/published/d6fde455-c912-4320-8088-e5cc6bb43642.js",
  },
  // Chile
  cl: {
    id: "curator-feed-mbp-chile-layout",
    src: "https://cdn.curator.io/published/f03e9237-9a7b-402a-b8cf-6ae8b2f3d218.js",
  },
  // Hungary
  hu: {
    id: "curator-feed-mbp-hungary-layout",
    src: "https://cdn.curator.io/published/1d2c1b20-4cc0-40eb-a7ef-ffddd281eee1.js",
  },
  // Uruguay
  uy: {
    id: "curator-feed-mbp-uruguay-layout",
    src: "https://cdn.curator.io/published/f80f6876-51c2-4e8f-8a0d-217d62b2d2ed.js",
  },
  // Kazakhstan
  kz: {
    id: "curator-feed-morbidelli-kazakhstan-layout",
    src: "https://cdn.curator.io/published/21487ab1-b469-4259-8eeb-31c8beb96abd.js",
  },
  // Romania
  ro: {
    id: "curator-feed-morbidelli-romania-layout",
    src: "https://cdn.curator.io/published/e5200875-bc6d-475b-8b05-9622e7d2811a.js",
  },
};

const mainStore = useMainStore();

const curatorSettings = reactive(
  curatorFeeds[mainStore.country]
    ? curatorFeeds[mainStore.country]
    : curatorFeeds.default
);

const scripts = [];
if (process.browser && mainStore.currentSite.settings.curator) {
  scripts.push({
    // class: '_iub_cs_activate',
    // 'data-suppressedsrc': curatorFeeds[mainStore.country] ? curatorFeeds[mainStore.country].src : curatorFeeds.default.src
    // 'data-iub-purposes': 3,
    crossorigin: true,
    defer: true,
    // type: 'text/plain'
    src: curatorFeeds[mainStore.country]
      ? curatorFeeds[mainStore.country].src
      : curatorFeeds.default.src,
  });
}

useHead({
  script: scripts,
});
</script>

<style lang="postcss">
@media screen and (max-width: 768px) {
  .crt-widget-carousel {
    .crt-controls-outside {
      @apply text-center px-0 !important;

      button {
        @apply inline relative bottom-[-60px] !important;

        &.crt-panel-prev {
          @apply me-4 !important;
        }
      }
    }

    .crt-match-heights .crt-carousel-pane {
      border: 1px solid transparent !important;
    }
  }
}

.crt-feed-window {
  @apply !h-auto;
}

.crt-widget-waterfall {
  min-height: auto !important;
  padding-bottom: 0 !important;
}

.crt-panel-next svg,
.crt-panel-prev svg {
  width: 100% !important;
}

.crt-widget {
  @apply !bg-black;
}
</style>
